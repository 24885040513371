import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'



//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe le PDF
import PDF from '../images/infographie-ademe.pdf'
import PDF2 from '../images/guide-comment-bien-chauffer-bois.pdf'

//On importe les composants
import Header from '../components/header'
import Adresse from '../components/adresse'
import Footer from '../components/footer'


//On importe le CSS
import '../style/recommandations.scss'

export default function Recommandations (){

  const Meta = {
    title: "Recommandations sur l'utilisation du bois de chauffage",
    description: "Afin de réduire l'émission de particules fines et autres polluants dans l'atmposhère, il est important de connaître certaines préconisations afin de limiter l'impact du chauffage au bois et améliorer ainsi la qualité de l'air.",
    canonical: "https://minucci.fr/recommandations"
  }


  return (
    <HelmetProvider>
      <Helmet>
        {/* A reprendre sur toutes les pages */}
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="icon" href={Favicon} />

        {/* A personnaliser sur toutes les pages */}
        <title>{Meta.title}</title>
        <meta name="description" content={Meta.description} />
        <link rel="canonical" href={Meta.canonical} />
      </Helmet>

      <div className="BackGroundIndex">

        <Header />

        <div className="MainRecommandations">

          <h1 align="center">RECOMMANDATIONS</h1>
          <h2 align="center">Ensemble préservons la qualité de l’air</h2>

          <p align="justify">Le bois représente 20% des énergies renouvelable mais génère <b>41% des émissions de particules fines</b> dans l’atmosphère (PM2,5). Lors de sa combustion, il émet également des composés organiques volatiles et des hydrocarbures aromatiques polycycliques nocifs pour la santé. Il est donc important de suivre les recommandations ci-dessous pour abaisser ces émissions.</p>

          <h3 align="left">BRÛLER DU BOIS SEC</h3>

          <p align="justify">Avec un combustible sec, vous produisez jusqu’à <b>10 fois moins de particules fines</b> rejetées dans l’atmosphère. Pour réduire l’émission de polluants dans l’air, il faut brûler du bois bien sec. C’est-à-dire contenant <b>moins de 23% d’humidité</b>.</p>
          <p align="justify">Vous pouvez contrôler le taux d’humidité du bois à l’aide d’un humidimètre. Vérifiez bien que votre appareil calcul le <b>taux d’humidité sur masse brut</b> et non le taux d’humidité sur masse sèche. Par exemple, 23% d’humidité sur masse brut équivaut à 30% d’humidité sur masse sèche.</p>
          <p align="justify">Brûler du bois de chauffage sec, permet également de <b>générer plus de chaleur </b>et donc d’utiliser moins de combustible. De plus, retirer tout ou partie de l’écorce permet de meilleur rendement de combustion, <b>l’écorce brûlant très mal</b>.</p>
          <p align="justify">Pour brûler du bois sec, vous pouvez l’acheter directement dans le commerce. Reportez-vous à l’étiquette du fournisseur de bois. Attention, il est important de le conserver dans de bonnes conditions (voir ci-dessous) pour ne pas qu’il reprenne l’humidité.</p>
          <p align="justify">Vous pouvez acheter du bois contenant plus de 23% d’humidité et le laisser sécher pendant au moins 18 mois. <b>Vérifiez le taux d’humidité du bois avant de la brûler</b>.</p>
          <p align="justify">Prenez la mesure au tiers de la longueur de la bûche et à au moins 1cm de profondeur. Vérifiez bien que la pointe de votre humidimètre ne soit pas pliée.</p>

          <h3 align="left">SECHAGE OU CONSERVATION</h3>

          <p align="justify">Avant toute chose, il faut savoir que plus les bûches sont courtes, plus vites elles sèchent. De même un bois fendu sèchera plus rapidement que du bois rond.</p>
          <p align="justify">Idéalement, le bois doit être stocké à l’intérieur dans une pièce sèche et ventilée, comme un garage par exemple. Cependant, il est possible de le conserver à l’extérieur contre un mur par exemple aux conditions suivantes : l’abri doit être couvert, il doit être isolé du sol pour éviter les remontées humides par le sol et enfin il doit être situé dans un endroit ventilé. <b></b>Le séchage à l’air est le mode de séchage le plus écologique et doit être privilégier afin de réduire nos émissions de CO2 et notre impact sur l’environnement. <a href="/">Découvrez nos valeurs</a></p>
          <p align="justify">Si votre bois est stocké à l’extérieur, rentrez le 48h avant pour qu’il soit bien sec. La <b>création d’un stock tampon</b> à proximité de l’appareil est idéal particulièrement en hiver.</p>

          <h3 align="left">ALLUMAGE DE VOTRE FOYER</h3>

          <p align="justify">Avant tout, utilisez une <b>longueur de bûches adaptée à votre appareil</b>. Reportez-vous à la notice d’utilisation de votre appareil. Si vous ne trouvez pas cette information, mesurez la taille de votre foyer et retirez 10cm. Par exemple, pour un foyer mesurant 50cm de long, privilégiez des bûches de 40cm ou moins.</p>
          <p align="justify">Pour l’allumage de votre foyer, il est recommandé d’avoir recours à un <b>allumage par le haut</b>. Cela peut paraître contreproductif, pourtant cela permet de réduire significativement l’émission de fumée et donc de polluant dans l’air.</p>
          <p align="justify">Cette technique permet de <b>brûler les fumées</b> lors de l’allumage alors qu’elles se seraient échappées dans votre conduit de cheminée avec un allumage « classique » par le bas.</p>
          <p align="justify">En premier lieu, ouvrez les trappes de tirage d’air au maximum puis ouvrez votre foyer. Mettez une première rangée de bûches, puis mettez par-dessus du petit bois bien sec. Enfin complété le tout par un allume-feu de préférence naturel.</p>
          <p align="justify">L’allume feu en s’embrasant va consumer le petit bois qui va former un lit de braise qui allumera les bûches du dessous. C’est aujourd’hui la <b>technique d’allumage la plus efficace</b> d’un point de vue environnemental.</p>

          <h3 align="left">ALLER PLUS LOIN</h3>
          <p align="justify">Si vous êtes à la recherche de plus amples informations, vous pouvez consulter le site de l’ADEME (Agence de l'environnement et de la maîtrise de l'énergie).</p>

          <a href={PDF} target="blanck">Télécharger l'infographie de l'ADEME</a>
          
          <br/>
          <br/>

          <a href={PDF2} target="blanck">Télécharger le guide de l'ADEME "Comment bien se chauffer au bois"</a>


        </div>


      </div>

      <Adresse />

      <Footer />




    </HelmetProvider>
  )
}
